"use client"

import React from "react"

import { useSession } from "@supernovaio/cloud/features/auth/useSession"

import { env } from "../../env.mjs"

import { FeatureFlagsIdentify } from "./FeatureFlagsIdentify"

import { LDProvider } from "launchdarkly-react-client-sdk"

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode
}) {
  // Set the default user on init, since if we use the async `identify`, some flags might already be loaded
  // incorrectly before the identification goes through
  const { data: sessionData } = useSession()
  const user = sessionData?.user
  const userId = user?.id
  const email = user?.email

  return (
    <LDProvider
      clientSideID={env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID}
      context={userId && email ? { key: userId, email } : undefined}
    >
      <FeatureFlagsIdentify />
      {children}
    </LDProvider>
  )
}
