"use client"

import { UserTheme } from "@supernovaio/sdk"

import { useSDKMutation } from "../useSDKMutation"

import { useMe } from "./useMe"

export function useSaveTheme() {
  const { data: me } = useMe()

  const { mutate: saveTheme } = useSDKMutation<NonNullable<UserTheme>>({
    async mutationFn(sdk, theme) {
      if (!me) {
        return
      }

      await sdk.me.updateProfile(me.id, {
        // update colors in theme only when having custom preset
        theme:
          theme.preset === "Custom"
            ? theme
            : // in case of not custom preset, we don't want to update colors
              {
                preset: theme.preset,
                isEditorWhite: theme.isEditorWhite,
              },
      })
    },
  })

  return { saveTheme }
}
