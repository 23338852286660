import { useCallback } from "react"

import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"
import {
  Supernova,
  UserRole,
  WorkspaceProfile,
  type Membership,
} from "@supernovaio/sdk"

import { useQueryClient, UseQueryResult } from "@tanstack/react-query"

import { useSafeQuery } from "../useSafeQuery"

export const membershipsQueryKey = ["memberships"]

export type EffectiveMembership = Pick<Membership, "role"> & {
  workspace: {
    id: string
    profile: Pick<WorkspaceProfile, "name" | "avatar">
  }
}

export function useInvalidateMemberships() {
  const queryClient = useQueryClient()

  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: membershipsQueryKey,
      }),
    [queryClient]
  )
}

export const mutationFn = async (sdk: Supernova, userId: string) => {
  if (!userId) {
    return []
  }

  const rawRemoteMemberships = await sdk.workspaces.memberships(userId)

  // since we are prefetching it on the server, we need to return seriazible objects, not class
  const memberships: EffectiveMembership[] = rawRemoteMemberships.map((m) => ({
    workspace: {
      id: m.workspace.id,
      profile: {
        name: m.workspace.profile.name,
        avatar: m.workspace.profile.avatar,
      },
    },
    role: m.effectiveRole,
  }))

  return memberships
}

export const useMemberships = (
  userId: string | undefined,
  options: {
    /**
     * Filter "only-owned" filters out all memberships where effective user role is not owner.
     * @default "include-all"
     */
    filter: "only-owned" | "include-all"
  } = { filter: "include-all" }
): UseQueryResult<EffectiveMembership[], Error> => {
  return useSafeQuery({
    enabled: !!userId,
    queryKey: membershipsQueryKey,
    queryFn: async () => {
      if (!userId) {
        return []
      }

      const sdk = await getClientSdk()

      return mutationFn(sdk, userId)
    },
    select(memberships) {
      if (options.filter === "only-owned") {
        return memberships.filter(
          (membership) => membership.role === UserRole.owner
        )
      }

      return memberships
    },
  })
}
