"use client"

import { PropsWithChildren, useEffect, useRef } from "react"

import { usePathname, useSearchParams } from "next/navigation"

import { useSegmentPageScope } from "@supernovaio/cloud/features/segment/SegmentPageScope"
import { useSegment } from "@supernovaio/cloud/features/segment/SegmentProvider"
import { SegmentPageMetadata } from "@supernovaio/cloud/features/segment/types"

type Props = PropsWithChildren &
  Partial<SegmentPageMetadata> & {
    /*
     * If `true`, only the first URL triggers a page view.
     * It is useful to avoid simultaneous tracking from multiple page trackers (e.g. from a spreadsheet and a modal).
     *
     * If `false`, every URL change triggers a page view.
     * It is useful for pages that have multiple internal pages (e.g. documentation editor).
     *
     * Default: `true`
     */
    isTrackingFirstUrl?: boolean
  }

/*
 * Component that tracks a page view in Segment.
 * It uses the `category` and `name` provided as props, or the ones set in the nearest `SegmentPageScope`.
 *
 * @param name, Name the page. If it consists of several parts, they can be separated with `/`, e.g. `Workspace / Members`
 */
export function SegmentPageTracker({
  category,
  name,
  isTrackingFirstUrl = true,
  children,
}: Props) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { setLocation, trackPage } = useSegment()
  const segmentPageScope = useSegmentPageScope()

  const actualCategory = category || segmentPageScope?.category
  const actualName = name || segmentPageScope?.name

  const initialPathnameRef = useRef<string>(pathname)
  const initialSearchParamsStringRef = useRef<string>(searchParams.toString())

  const searchParamsString = searchParams.toString()

  // Set the current location in the SegmentProvider so it is used in all trackEvents
  useEffect(() => {
    const location =
      actualCategory && actualName
        ? `${actualCategory} / ${actualName}`
        : actualCategory || actualName || undefined
    setLocation(location)
    return () => {
      setLocation(undefined)
    }
  }, [actualCategory, actualName, setLocation])

  useEffect(() => {
    ;(async () => {
      const initialPathname = initialPathnameRef.current
      const initialSearchParamsString = initialSearchParamsStringRef.current

      if (
        isTrackingFirstUrl &&
        (initialPathname !== pathname ||
          initialSearchParamsString !== searchParamsString)
      ) {
        return
      }

      await trackPage({ category: actualCategory, name: actualName })
    })()
  }, [
    trackPage,
    pathname,
    searchParamsString,
    actualCategory,
    actualName,
    isTrackingFirstUrl,
  ])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
